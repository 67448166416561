<template>

  <div class="row" ref="projectForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Name"
                            name="name"
                            fou
                            @keyup="generateMetaTitleURL"
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Url"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="link_title"
                  rules=""
                  name="The Link Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Link Title"
                            name="link_title"
                            fou
                            v-model="formData.link_title">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Featured Project</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
               <ValidationProvider
                  vid="meta_title"
                  rules="required"
                  name="The Meta Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Title"
                            name="meta_title"
                            fou
                            v-model="formData.meta_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="meta_description"
                  rules=""
                  name="The Meta Description"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Description"
                            name="meta_description"
                            fou
                            v-model="formData.meta_description">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="excerpt"
                  rules=""
                  name="The excerpt"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                            :error="failed ? errors[0]: null"
                            label="excerpt"
                            name="excerpt"
                            fou
                            v-model="formData.excerpt">
                  </fg-text>
                </ValidationProvider>

              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label>Description</label>
                <editor

                  v-model="formData.description"
                  :api-key="editorKey"
                  :init='{
                    height: 300,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                />
              </div>

            </div>

            <div class="col-md-8 col-sm-12">

              <div class="form-group">
                <label>thumbnail Image</label>
                <el-tooltip placement="right" v-if="getBannerImageInfo()">
                  <div slot="content">{{ getBannerImageInfo() }}</div>
                  <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                </el-tooltip>

                <image-uploader
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  v-model="formData.image"></image-uploader>

              </div>

            </div>
            <div class="col-md-8 col-sm-12">

              <div class="form-group">
                <label>Cover Image</label>
                <el-tooltip placement="right" v-if="getBannerImageInfo()">
                  <div slot="content">{{ getBannerImageInfo() }}</div>
                  <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                </el-tooltip>

                <image-uploader
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  v-model="formData.cover_image"></image-uploader>

              </div>

            </div>

            <div class="row mt-4">

              <h4 class="card-title">Project Gallery
              </h4>

              <div class="col-12">
                <div class="row">
                  <template v-for="(image,imageIndex) in formData.images">
                    <div class="col-3">
                      <div class="col-12">
                        <label>Gallery Image:</label>
                      <el-tooltip placement="right" v-if="getBannerImageInfo('banner_image_projects')">
                        <div slot="content">{{getBannerImageInfo('banner_image_projects')}}</div>
                        <span style="margin: 0 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                        </span>
                      </el-tooltip>
                        <image-uploader
                          :preview-width="'200px'"
                          :preview-height="'200px'"
                          v-model="image.image"></image-uploader>
                      </div>
                      <div class="col-12" style="display: flex ; justify-content: flex-start;align-items: center">
                        <fg-input type="number"
                                  label="order"
                                  style="max-width: 50%;"
                                  fou
                                  v-model="image.sort_order">
                        </fg-input>
                        <div class="ml-4">
                          <l-button
                            @click="removeImage(imageIndex)"
                            type="danger" size="sm">
                                <span class="btn-label">
                                   <i class="fa fa-trash"></i>
                                </span>
                          </l-button>
                        </div>
                      </div>
                    </div>

                  </template>
                </div>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewImage()"
                  size="sm">
                       <span class="btn-label">
                         add new image
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import { Tooltip} from 'element-ui';
import {FormGroupSelect, ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    FgSelect,
    [Tooltip.name]: Tooltip,
    LSwitch,
    FormGroupSelect,
    ImageUploader,
    'editor': Editor
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })

  },


  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey:process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        description: "",
        url: "",
        meta_title: "",
        meta_description: "",
        is_active: false,
        is_featured: false,
        sort_order: null,
        images: [],
        sizes: [],
        link_title:'READ MORE',
        image:null,
        excerpt:null,
        cover_image:null
      },


    };
  },

  mounted() {
     this.loader = this.$loading.show({
      container: this.$refs.productForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Project";
    } else {
      this.editMode = false;
      this.formTitle = "Add Project";
    }
    this.prepareBuilders();
  },




  methods: {

    async getProject() {
      if (!this.editMode) {
        return;
      }
      try {
        let response = await this.axios.get("projects/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Project Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
      }
    },
    async prepareBuilders() {
      await this.getProject();
      this.loader.hide();
    },
    async submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (!this.formData.image) {
        this.$notify({
          message: "You Must Upload THUMBNAIL  Image",
          timeout: 2000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      if (!this.formData.cover_image) {
        this.$notify({
          message: "You Must Upload cover Image",
          timeout: 2000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      let imageValidation = false;
      for (let image of this.formData.images) {
        if (!image.image) {
          imageValidation = true;
        }
      }
      if (imageValidation) {
        this.$notify({
          message: "You Must Upload Image",
          timeout: 2000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      if (this.editMode === true) {
        request = this.axios.put("projects/update/" + this.id, this.formData);
        successMessage = "Projects Updated Successfully";
      } else {
        request = this.axios.post("projects/create", this.formData);
        successMessage = "Projects Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/projects/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    generateMetaTitleURL() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
      // meta title
      this.formData.meta_title = this.formData.name;
    },

    addNewImage() {
      let order = this.formData.images.length + 1;
      this.formData.images.push({"id": null, "image": "", sort_order: order});
    },
    removeImage(index) {
      this.formData.images.splice(index, 1);
    },
    getBannerImageInfo($key) {
      if(this.mediaInfo && this.mediaInfo[$key]) {
        return this.mediaInfo[$key];
      } else {
        return "";
      }
    }


  }

}
</script>

<style>
</style>
